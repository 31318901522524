import React from 'react';
import { FaAward } from 'react-icons/fa';
import { MdOutlineTipsAndUpdates } from 'react-icons/md';
import { RiFocusLine } from 'react-icons/ri';
import ME from '../../assets/me.svg';
import './about.css';

const About = () => {
  return (
    <section id="about">
      <h5>Get To Know</h5>
      <h2>About Me</h2>

      <div className="container about__container">
        <div className="about__me">
          <div className="about__me-image">
            <img src={ME} alt="me" />
          </div>
        </div>

        <div className="about__content">
          <div className="about__cards">
            <article className="about__card">
              <FaAward className="about__icon" />
              <h5>Professional</h5>
              <small>Biologist</small>
            </article>

            <article className="about__card">
              <RiFocusLine className="about__icon" />
              <h5>Focus</h5>
              <small>Health & Wellness</small>
            </article>

            <article className="about__card">
              <MdOutlineTipsAndUpdates className="about__icon" />
              <h5>12 Tips</h5>
              <small>Care of Elderly Loved Ones</small>
            </article>
          </div>

          <p>
            Keeping the elderly at home poses some challenges, but there are
            many different services that can minimize the family burden,
            increase the independence of the elderly, improve their security and
            confidence, helping them at this stage of their lives to live
            successfully at home. We practice conscious communication, with
            attention and focus centered on the person.
          </p>

          <a href="#contact" className="btn btn-primary">
            Let's Talk
          </a>
        </div>
      </div>
    </section>
  );
};

export default About;
