import emailjs from 'emailjs-com';
import React, { useRef } from 'react';
import { HiOutlineDesktopComputer } from 'react-icons/hi';
import { MdOutlineEmail, MdOutlineLocationOn } from 'react-icons/md';
import './contact.css';

const Contact = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm(
      'service_p13a45q',
      'template_njd5573',
      form.current,
      'PO3-TFuJQdViUGSbM'
    );

    e.target.reset();
  };

  return (
    <section id="contact">
      <h5>Get In Touch</h5>
      <h2>Contact Me</h2>

      <div className="container contact__container">
        <div className="contact__options">
          <article className="contact__option">
            <MdOutlineEmail className="contact__option-icon" />
            <h4>Email</h4>
            <h5>bhcinfo@gmail.com</h5>
            <a
              href="mailito:bhcinfo@gmail.com"
              target="_blank"
              rel="noreferrer"
            >
              Send a message
            </a>
          </article>

          <article className="contact__option">
            <HiOutlineDesktopComputer className="contact__option-icon" />
            <h4>besthomecare.codevscript.com</h4>
            <h5></h5>
            <a
              href="https://www.besthomecare.codevscript.com"
              target="_blank"
              rel="noreferrer"
            >
              website
            </a>
          </article>

          <article className="contact__option">
            <MdOutlineLocationOn className="contact__option-icon" />
            <h4>United States | EE.UU.</h4>
            <h5>New York</h5>
            <a href="https://www.besthomecare.codevscript.com">New Jersey</a>
          </article>
        </div>
        {/*END OF CONTACT OPTION*/}
        <form ref={form} onSubmit={sendEmail}>
          <input
            type="text"
            name="name"
            placeholder="Your Full Name"
            required
          />
          <input type="email" name="email" placeholder="Your Email" required />
          <textarea
            name="message"
            rows="7"
            placeholder="Your Message"
            required
          ></textarea>
          <button type="submit" className="btn btn-primary">
            Send Message
          </button>
        </form>
      </div>
    </section>
  );
};

export default Contact;
