import React from 'react';
import AVTR1 from '../../assets/avatar1.svg';
import AVTR2 from '../../assets/avatar2.svg';
import AVTR3 from '../../assets/avatar3.svg';
import './testimonials.css';

import { Pagination } from 'swiper';

import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/pagination';

const data = [
  {
    avatar: AVTR1,
    name: 'Louise sister in law',
    review:
      'Good morning Maria, I just wanted to share that my sister-in-law went to see mom. She said how wonderful the house looked and how nice mommy looked and that you were taking such good care of her. She´s so glad you´re staying with her... So on behalf of all of us, we thank you. 10/10/21',
  },
  {
    avatar: AVTR2,
    name: 'Louise sister in law',
    review: 'thank you very much you are the best 12/30/21',
  },
  {
    avatar: AVTR3,
    name: 'Louise sister in law',
    review:
      'Thank you for taking such good care of my mother, she really appreciates you and all of us, you are doing an exceptional job. I just want you to know that we really appreciate you. 2/7/21',
  },
  ////
  {
    avatar: AVTR3,
    name: 'Louise Daughter in law',
    review:
      'Thank you for always being on the ball and making sure everything is right with Mom. We appreciate you so much and you re doing a great job. She can be a little tough sometimes. 7/24/23',
  },
  {
    avatar: AVTR3,
    name: 'Louise Daughter in law',
    review:
      'Good morning. Thank you so much for being able to stay with Mom. Shes so comfortable with you and that will ease some of her anxiety. 8/12/23',
  },
  {
    avatar: AVTR3,
    name: 'Louise Daughter in law',
    review:
      'We re so grateful that you re willing and I know mom will feel more at peace with you there... Thank you so much. 8/12/23',
  },
  {
    avatar: AVTR3,
    name: 'Louise Daughter in law',
    review: 'We really do appreciate all you do and are doing for Mom. 8/12/23',
  },
  {
    avatar: AVTR3,
    name: 'Louise Daughter in law',
    review:
      'Thank you so much. We all appreciate you more than you know and you do a wonderful thing for Mom. 8/13/23',
  },
  {
    avatar: AVTR3,
    name: 'Patient asking from the hospital',
    review:
      'Good morning. Are you coming to Moms today. Shes asking if youll be there. 8/15/23',
  },
  {
    avatar: AVTR3,
    name: 'Louise Daughter in law',
    review: 'Thank you for such a detailed update we love you. 8/16/23',
  },
  {
    avatar: AVTR3,
    name: 'Louise Son',
    review: 'Thats great news, thank you so much. 8/16/23',
  },
  {
    avatar: AVTR3,
    name: 'Louise Daugther in law',
    review:
      'Thats wonderful! Thank you for taking such good care of her. 8/16/23',
  },
  {
    avatar: AVTR3,
    name: 'Louise Daugther in law',
    review: 'Oh thats good to hear....thank you for updating always. 8/17/23',
  },
  {
    avatar: AVTR3,
    name: 'Louise Son',
    review:
      'Thanks for the update I think shes doing great also thank you so much for all you do. 8/17/23',
  },
  {
    avatar: AVTR3,
    name: 'Louise Daugther in law',
    review: 'Such great news! Thank you for keeping us updated. 8/17/23',
  },
];

const Testimonials = () => {
  return (
    <section id="testimonials">
      <h5>My clients</h5>
      <h2>SAY</h2>

      <Swiper
        className="container testimonials__container"
        modules={[Pagination]}
        spaceBetween={40}
        slidesPerView={1}
        pagination={{ clickable: true }}
      >
        {data.map(({ avatar, name, review }, index) => {
          return (
            <SwiperSlide key={index} className="testimonial">
              <div className="client__avatar">
                <img src={avatar} alt="avatar" />
              </div>
              <h5 className="client__name">{name}</h5>
              <small className="client__review">{review}</small>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </section>
  );
};

export default Testimonials;
