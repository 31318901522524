import React from 'react';
import { FaAward } from 'react-icons/fa';
import { MdOutlineTipsAndUpdates } from 'react-icons/md';
import { RiFocusLine } from 'react-icons/ri';
import './footer.css';

const Footer = () => {
  return (
    <footer>
      <a href="#" className="footer__logo">
        Best HOME Care
      </a>

      <ul className="permalinks">
        <li>
          <a href="#">Home</a>
        </li>
        <li>
          <a href="#about">About</a>
        </li>
        <li>
          <a href="#experience">Experience</a>
        </li>
        <li>
          <a href="#services">Services</a>
        </li>
        <li>
          <a href="#portfolio">Portfolio</a>
        </li>
        <li>
          <a href="#testimonials">Testimonials</a>
        </li>
        <li>
          <a href="#contact">Contact</a>
        </li>
      </ul>

      <div className="footer__socials">
        <a href="#">
          <FaAward />
        </a>
        <a href="#">
          <RiFocusLine />
        </a>
        <a href="#">
          <MdOutlineTipsAndUpdates />
        </a>
      </div>

      <div className="footer__copyright">
        <small>&copy;codevscript 2023</small>
      </div>
    </footer>
  );
};

export default Footer;
