import React from 'react'
import './experience.css'
import { FiCheckCircle } from 'react-icons/fi'

const Experience = () => {
  return (
    <section id='experience'>
      <h5>Competence and</h5>
      <h2>Excellence</h2>

      <div className="container experience__container">
        <div className="experience__frontend">
          <h3>skills1</h3>
          <div className="experience__content">
            <article className='experience__details'>
              <FiCheckCircle className='experience__details-icon'/>              
              <div>
              <h4>Engage customers by talking with them, planning and <br/> serving them.</h4>
              
              <small className='text-light'>100%</small>
              </div>              
            </article>
            <article className='experience__details'>
              <FiCheckCircle className='experience__details-icon'/>
              <div>              
              <h4>Planning, preparation and nutrition of meals taking into <br/> account dietary restrictions and preferences.</h4>
              <small className='text-light'>100%</small>
              </div>             
            </article>
            <article className='experience__details'>
              <FiCheckCircle className='experience__details-icon'/>
              <div>              
              <h4>Genuine interest in customers listening to their needs, <br/> making sure their environment is clean and safe.</h4>
              <small className='text-light'>100%</small>            
              </div> 
            </article>
            <article className='experience__details'>
              <FiCheckCircle className='experience__details-icon'/>
              <div>      
              <h4>Monitoring clients, recording and reporting any <br/> developments or concerns to appropriate parties <br/> or family members.</h4>
              <small className='text-light'>100%</small>            
              </div>                                                                     
            </article>
            <article className='experience__details'>
              <FiCheckCircle className='experience__details-icon'/>
              <div>
              <h4>Accompanying clients to appointments, outings or <br/> planned, authorized and confirmed events.</h4>
              <small className='text-light'>100%</small>
              </div>
            </article>
            <article className='experience__details'>
              <FiCheckCircle className='experience__details-icon'/>
              <div>              
              <h4>Provide emotional support to clients as they face <br/> their prospects and conditions.</h4>
              <small className='text-light'>100%</small>
              </div>             
            </article>
          </div>
        </div>
        {/* ===== END FRONTEND ===== */}

        <div className="experience__backend">
          <h3>skills2</h3>
          <div className="experience__content">
            <article className='experience__details'>
              <FiCheckCircle className='experience__details-icon'/>
              <div>
              <h4>Bilingual, professional education, experience, skills <br/> and specific interests with training.</h4>
              <small className='text-light'>100%</small>
              </div>              
            </article>
            <article className='experience__details'>
              <FiCheckCircle className='experience__details-icon'/>
              <div>
              <h4>Excellent references, valid driver's license and <br/> clean driving record.</h4>
              <small className='text-light'>100%</small>
              </div>
            </article>
            <article className='experience__details'>
              <FiCheckCircle className='experience__details-icon'/>
              <div>
              <h4>Strong communication, listening, planning and <br/> structured reasoning skills.</h4>
              <small className='text-light'>100%</small>
              </div>
            </article>
            <article className='experience__details'>
              <FiCheckCircle className='experience__details-icon'/>
              <div>
              <h4>Safety procedures, follow the dietary, exercise <br/> and medication plans strictly prescribed by your doctor.</h4>
              <small className='text-light'>100%</small>
              </div>
            </article>
            <article className='experience__details'>
              <FiCheckCircle className='experience__details-icon'/>
              <div>              
              <h4>We accurately observe and report changes <br/> and behaviors on the status of the elderly.</h4>
              <small className='text-light'>100%</small>
              </div>              
            </article>
            <article className='experience__details'>
              <FiCheckCircle className='experience__details-icon'/>
              <div>              
              <h4>We keep the needs of the elderly as a top priority.</h4>
              <small className='text-light'>100%</small>
              </div>              
            </article>
          </div>
        </div>
      </div>      
    </section>
  )
}

export default Experience