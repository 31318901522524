import React from 'react'
import './services.css'
import { BsCalendar2Day } from 'react-icons/bs'
import { BsCalendarWeek } from 'react-icons/bs'
import { BsCalendar2Month } from 'react-icons/bs'

const Services = () => {
  return (
    <section id='services'>
        <h5>What I Offer</h5>
        <h2>Coordinate your Options</h2>

        <div className='container services__container'>
          <article className='service'>
            <div className="service__head">
              <h3>Daily</h3>
            </div>

            <ul className='service__list'>
            <li>
              <BsCalendar2Day className='service__list-icon'/>
              <p>Personal care</p>
            </li>
            <li>
              <BsCalendar2Day className='service__list-icon'/>
              <p>Support at home</p>
            </li>
            <li>
              <BsCalendar2Day className='service__list-icon'/>
              <p>Primary Care</p>
            </li>
            <li>
              <BsCalendar2Day className='service__list-icon'/>
              <p>Emotional care</p>
            </li>
            <li>
              <BsCalendar2Day className='service__list-icon'/>
              <p>Walk your pet</p>
            </li>
          </ul>
          </article>
        {/* END DAYS/ */} 
        <article className='service'>
            <div className="service__head">
              <h3>Weekly</h3>
            </div>

            <ul className='service__list'>
            <li>
              <BsCalendarWeek className='service__list-icon'/>
              <p>Personal care</p>
            </li>
            <li>
              <BsCalendarWeek className='service__list-icon'/>
              <p>Complete Care</p>
            </li>
            <li>
              <BsCalendarWeek className='service__list-icon'/>
              <p>Complete Support at home</p>
            </li>
            <li>
              <BsCalendarWeek className='service__list-icon'/>
              <p>Comprehensive Emotional Care</p>
            </li>
            <li>
              <BsCalendarWeek className='service__list-icon'/>
              <p>Meaningful activities and conversation</p>
            </li>
            <li>
              <BsCalendarWeek className='service__list-icon'/>
              <p>Well-being of your pet</p>
            </li>
          </ul>
          </article>
          {/* END WEEK/ */}
          <article className='service'>
            <div className="service__head">
              <h3>Monthly</h3>
            </div>

            <ul className='service__list'>
            <li>
              <BsCalendar2Month className='service__list-icon'/>
              <p>Comprehensive personal care</p>
            </li>
            <li>
              <BsCalendar2Month className='service__list-icon'/>
              <p>Comprehensive home support</p>
            </li>
            <li>
              <BsCalendar2Month className='service__list-icon'/>
              <p>Comprehensive care</p>
            </li>
            <li>
              <BsCalendar2Month className='service__list-icon'/>
              <p>Comprehensive Emotional Care</p>
            </li>
            <li>
              <BsCalendar2Month className='service__list-icon'/>
              <p>Time and care for your pet</p>
            </li>
            </ul>
            </article> 
        </div>
      </section>
  )
}

export default Services