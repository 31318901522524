import React from 'react'
import { FaMobileAlt } from 'react-icons/fa'
import { FaTabletAlt } from 'react-icons/fa'
import { FaDesktop } from 'react-icons/fa'


const HeaderSocials = () => {
  return (
    <div className='header__socials'>
      <a href=""><FaMobileAlt /></a>
      <a href=""><FaTabletAlt /></a>
      <a href=""><FaDesktop/></a>
    </div>
  )
}

export default HeaderSocials
