import React from 'react';
import log from '../../assets/log.svg';
import CTA from './CTA';
import './header.css';
import HeaderSocial from './HeaderSocials';

const Header = () => {
  return (
    <header>
      <div className="container header__container">
        <h5>Senior support services</h5>
        <h1>
          Best <span>HOME</span> Care
        </h1>
        <h5 className="text-light">
          Keeping the care of your loved one at home is possible!
        </h5>
        <CTA />
        <HeaderSocial />

        <div className="me">
          <img src={log} alt="log" />
        </div>

        <a href="#contact" className="scroll__down">
          Scroll Down
        </a>
      </div>
    </header>
  );
};

export default Header;
