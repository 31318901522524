import React from 'react'
import './portfolio.css'
import IMG1 from '../../assets/mw1.png'
import IMG2 from '../../assets/mw2.png'
import IMG3 from '../../assets/mw3.png'
import IMG4 from '../../assets/mw4.png'
import IMG5 from '../../assets/mw5.png'
import IMG6 from '../../assets/mw6.png'

const Portfolio = () => {
  return (
    <section id='portfolio'>
      <h5>MY WORK PLAN</h5>
      <h2>Complementing the resources</h2>
      {/*img1 */}
      <div className="container portfolio__container">
        <article className='portfolio__item'>
          <div className="portfolio__item-image">
            <img src={IMG1} alt="" />
          </div>
            <h3>Personal Care</h3>
            {/*<div className='portfolio__item-cta'>
              <a href="#" className='btn' target='_blank'>img1</a>
              <a href="#" className='btn btn-primary' target='_blank'>Demo</a>
            </div>*/}         
        </article>
        {/*img2 */}
        <article className='portfolio__item'>
          <div className="portfolio__item-image">
            <img src={IMG2} alt="" />
          </div>
            <h3>Homemaking</h3>
            {/*<div className='portfolio__item-cta'>
              <a href="#" className='btn' target='_blank'>img1</a>
              <a href="#" className='btn btn-primary' target='_blank'>Demo</a>
            </div>*/}          
        </article>
        {/*img3 */}
        <article className='portfolio__item'>
          <div className="portfolio__item-image">
            <img src={IMG3} alt="" />
          </div>
            <h3>Mealtime</h3>
            {/*<div className='portfolio__item-cta'>
              <a href="#" className='btn' target='_blank'>img1</a>
              <a href="#" className='btn btn-primary' target='_blank'>Demo</a>
            </div>*/}          
        </article>
        {/*img4 */}
        <article className='portfolio__item'>
          <div className="portfolio__item-image">
            <img src={IMG4} alt="" />
          </div>
            <h3>Time attendance</h3>
            {/*<div className='portfolio__item-cta'>
              <a href="#" className='btn' target='_blank'>img1</a>
              <a href="#" className='btn btn-primary' target='_blank'>Demo</a>
            </div>*/}          
        </article>
        {/*img5 */}
        <article className='portfolio__item'>
          <div className="portfolio__item-image">
            <img src={IMG5} alt="" />
          </div>
            <h3>Monitoring</h3>
            {/*<div className='portfolio__item-cta'>
              <a href="#" className='btn' target='_blank'>img1</a>
              <a href="#" className='btn btn-primary' target='_blank'>Demo</a>
            </div>*/}          
        </article>
        {/*img6 */}
        <article className='portfolio__item'>
          <div className="portfolio__item-image">
            <img src={IMG6} alt="" />
          </div>
            <h3>Incidental Transportation</h3>
            {/*<div className='portfolio__item-cta'>
              <a href="#" className='btn' target='_blank'>img1</a>
              <a href="#" className='btn btn-primary' target='_blank'>Demo</a>
            </div>*/}          
        </article>
      </div>
    </section>
  )
}

export default Portfolio